"use client";

import { Box, Drawer, IconButton, Icon, Link } from "components";
import { useOptions, useRedirect } from "hooks";

function MobileMenu() {
  const options = useOptions();
  const redirect = useRedirect();

  return (
    <Box className="BrowserMobile-nav">
      <IconButton
        icon={!options.open ? "menu" : "close"}
        onClick={options.toggleMenu}
      />
      <Drawer options={options}>
        <Box className="BupDrawer-slider">
          <Box></Box>
        </Box>
        <Box className="BupLink-nav" component="li">
          <Link id="BD-home" to="/" navLink>
            Inicio
          </Link>
        </Box>
        <Box className="BupLink-nav" component="li">
          <Link id="BD-events" to="/panoramas" navLink>
            Panoramas
          </Link>
        </Box>
        <Box className="BupLink-nav" component="li">
          <Link id="BD-pricing" to="/precios" navLink>
            Servicios
          </Link>
        </Box>
        <Box className="BupLink-nav" component="li">
          <Link id="BD-business" to="/empresas" navLink>
            Empresas
          </Link>
        </Box>
        <Box className="BupLink-nav Bup-hidden" component="li">
          <Link id="BD-about" to="/nosotros" navLink>
            Nosotros
          </Link>
        </Box>
        <Box className="BupLink-nav" component="li">
          <Link id="BD-login" onClick={redirect.login}>
            Iniciar sesión
          </Link>
        </Box>
        <Box className="BupLink-nav" component="li">
          <Link id="BD-register" onClick={redirect.register}>
            Registro
          </Link>
        </Box>
      </Drawer>
    </Box>
  );
}

export default MobileMenu;
