"use client";

import { Box, Button, Link, Logo, Stack } from "components";
import classnames from "classnames";
import { useRedirect, useMedia } from "hooks";
import { usePathname } from "next/navigation";
import { PAGES } from "data/consts";
import { scrollTo } from "utils";
import Menu from "./Menu";

function Topbar({ company }) {
  const pathname = usePathname();
  const redirect = useRedirect();
  const screen = useMedia();

  return (
    <Box
      className={classnames("BupBrowser-nav", {
        "Bup-fixed": screen.top > 0,
        "Bup-home": pathname === "/",
      })}
      component="nav"
      id="web-nav"
    >
      <Box>
        <Link id="BT-logo" to="/">
          <Logo className="BupBrowser-logo" size="large" />
          <span className="BupLogo-span">{company?.alias}</span>
        </Link>
      </Box>
      <Box component="ul">
        {pathname === "/bup" && (
          <>
            <Box
              className="BupLink-nav"
              component="li"
              onClick={() => scrollTo("home-explore", -48)}
            >
              Proposito
            </Box>
            <Box
              className="BupLink-nav min-lg"
              component="li"
              onClick={() => scrollTo("home-discover", -48)}
            >
              Servicios
            </Box>
          </>
        )}
        {PAGES.includes(pathname) && (
          <>
            <Box className="BupLink-nav" component="li">
              <Link id="BT-events" to="/panoramas" navLink>
                Panoramas
              </Link>
            </Box>
            <Box className="BupLink-nav" component="li">
              <Link id="BT-pricing" to="/precios" navLink>
                Servicios
              </Link>
            </Box>
            <Box className="BupLink-nav" component="li">
              <Link id="BT-business" to="/empresas" navLink>
                Empresas
              </Link>
            </Box>
            <Box className="BupLink-nav Bup-hidden" component="li">
              <Link id="BT-about" to="/nosotros" navLink>
                Nosotros
              </Link>
            </Box>
          </>
        )}
      </Box>
      <Stack
        alignItems="center"
        className="BupBrowser-login"
        flexDirection="row"
        justifyContent="flex-end"
        spacing={1}
        useFlexGap
      >
        <Button
          className="BupButton-inherit"
          color="inherit"
          id="BT-login"
          onClick={redirect.login}
        >
          Iniciar sesión
        </Button>
        <Button
          className="BupButton-dark"
          color="primary"
          id="BT-signup"
          onClick={redirect.register}
          size="large"
        >
          Regístrate
        </Button>
      </Stack>
      <Menu />
    </Box>
  );
}

export default Topbar;
